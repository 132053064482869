import React, { useCallback, useState } from "react";
import get from 'lodash/get';

export default props => {
    const { record, source, label, minHeight = 100 } = props;
    const value = get(record, source) ?? '[NO_VALUE]';
    const text = typeof value !== 'string' ? JSON.stringify(value, null, 2) : value;
    const [isOpen, setOpen] = useState(false);
    const toggleCallback = useCallback(() => setOpen(!isOpen), [isOpen, setOpen]);
    return (
        <>
            <p className="grey" onClick={toggleCallback}>{label}:</p>
            <p className="grey" onClick={toggleCallback}>{isOpen ? '\u25BC' : '\u25B2'}</p>
            <pre className="textarea" style={{minHeight: minHeight + "px", display: isOpen ? 'inherit' : 'none'}}>
                {text}
            </pre>  
         </>
    );
};