import React, { useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useEditController } from 'react-admin';


import DeleteButton from '../DeleteButton';
import DetailForm from "../Form/DetailForm";
import TextInputField from "../Form/TextInputField";
import ContactFooterInlineUtm from "./ContactFooterInlineUtm";
import SelectInputField from "../Form/SelectInputField";
import DateInputField from "../Form/DateInputField";
import MailStatus from "../Form/MailStatus";
import BoolField from "../Form/BoolField";
import TextAreaInputField from "../Form/TextAreaInputField";

import useExportData from "../../utils/useExportData";

export const genderTypes = {
    'Male': 'Herr',
    'Female': 'Frau',
    'Diverse': 'Divers',
};


const T = props => {
    return (
        <>
            <div className="flex row">
                <div className="flex-item">
                    <DetailForm
                        formLabel="Kontaktdaten"
                        boxClassName="contact"
                        {...props}
                    >
                        <SelectInputField source="customer.gender" label="Geschlecht" options={genderTypes} />
                        <TextInputField source="customer.firstName" label="Vorname" />
                        <TextInputField source="customer.lastName" label="Nachname" />
                        <TextInputField source="customer.email" label="E-Mail-Adresse" />
                        <MailStatus source="customer.verified" label="E-Mail bestätigt" />
                        <TextInputField source="customer.phone" label="Telefonnummer" />
                        <BoolField label="Datenschutzzustimmung" source="customer.dataProtectionAgreedAt" />
                        <ContactFooterInlineUtm />
                    </DetailForm>
                </div>
                <div className="flex-item">
                    <DetailForm
                        formLabel="Nachricht"
                        boxClassName="overview"
                        {...props}
                    >
                    <DateInputField source="createdAt" label="Anfrage vom" />
                    <TextInputField source="recipient.email" label="Empfänger" />
                    <TextAreaInputField source="customer.message" label="Nachricht" minHeight={500}/>
                    </DetailForm>
                </div>
            </div>
        </>
    );
};

export default props => {
    const history = useNavigate();
    const editProps = useEditController({ ...props, undoable: false });
    const { exportCallback, viewCallback } = useExportData(editProps.record);

    const goBackCallback = useCallback(() => {
        history(-1);
    }, [history]);

    return (
        <div className={'details'}>
            <div className="flex space">
                <h2 className="symbol">Lead</h2>
                <button onClick={goBackCallback} className="btn btn-border"><i className="icon icon-chevron-left"></i>Zurück</button>
            </div>
            <T {...editProps} />
            <button className="export" onClick={exportCallback}>Export</button>
            <button className="export" onClick={viewCallback}>View</button>
            {editProps.record && <DeleteButton {...editProps}/>}
        </div>
        
    );
};
