import React, { useState, useCallback, isValidElement, useEffect } from "react";
import { Form } from 'react-final-form';
import isEqual from 'lodash/isEqual';

export default props => {
    const { boxClassName, formLabel, noEditMode, validationSchema, children, overlay, record, save, saving } = props;
    const [isEditMode, setIsEditMode] = useState(noEditMode ? true : false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isOverlayActive, setIsOverlayActive] = useState(false);
    const [compiledSchema, setCompiledSchema] = useState(undefined);
    const editModeCallback = useCallback(() => {
        setIsEditMode(!isEditMode);
    }, [isEditMode]);
    const saveCallback = useCallback((values) => {
        if (saving)
            return;
        setIsUpdating(true);
        save({ ...values }, null);
    }, [save, saving]);
    useEffect(() => {
        if (isUpdating && !saving) {
            !noEditMode && setIsEditMode(false);
            setIsUpdating(false);
        }
    }, [saving, setIsEditMode, isUpdating, setIsUpdating, noEditMode]);
    const showOverlayCallback = useCallback(() => {
        setIsOverlayActive(true);
    }, [setIsOverlayActive]);
    useEffect(() => {
        if (!validationSchema)
            return;
        //const cS = ajv.getSchema(validationSchema.$id) || ajv.compile(validationSchema);
        //setCompiledSchema(() => cS);
    }, [validationSchema, setCompiledSchema]);
    const validationCallback = useCallback((/*values*/) => {
        const errors = {};
        if (!compiledSchema)
            return errors;
        //const valid = compiledSchema(values);
        //if (valid || !compiledSchema.errors)
        //    return errors
        //localize_de(compiledSchema.errors);
        //compiledSchema.errors.forEach(err => {
         //   if (err.keyword === 'required')
         //       set(errors, err.instancePath.substring(1).replace('/', '.') + '.' + err.params.missingProperty, err.message);
         //   else
         //       set(errors, err.instancePath.substring(1).replace('/', '.'), err.message);
        //});
        return errors;
    }, [compiledSchema]);
    return (
        <div className={"shadow-box " + boxClassName}>
            <h3 className="underlined">{formLabel}</h3>
            {(!noEditMode || overlay) && <i className={'icon ' + (overlay ? 'icon-history' : 'icon-pencil')} onClick={overlay ? showOverlayCallback : editModeCallback}></i>}
            <Form
                onSubmit={saveCallback}
                initialValues={record ?? {}}
                initialValuesEqual={isEqual}
                keepDirtyOnReinitialize
                validate={validationSchema ? validationCallback : undefined}
                render={({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="details-info">
                                {React.Children.map(children, (field) =>
                                    isValidElement(field) ? React.cloneElement(field, {
                                        isEditMode,
                                        record,
                                        saving
                                    }) : null
                                )}
                            </div>
                            {overlay && isValidElement(overlay) && React.cloneElement(overlay, { record, isOverlayActive, setIsOverlayActive })}
                        </form>
                    );
                }}
            />
        </div>
    );
};