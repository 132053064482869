import React from "react";

const comparePS = (rules, values, and) => and ? rules.every(r => values.includes(r)) : rules.some(r => values.includes(r));

export default ({ record, statusData }) => {
    const status = record?.listing?.status ?? 'open';
    const processingSteps = record?.listing?.processingSteps ?? [];
    let sD = {
        color: 'gray',
        label: 'default'
    };
    for (const d of statusData) {
        if (d.status && !d.status.includes(status))
            continue;

        if (d.processingSteps && !comparePS(d.processingSteps, processingSteps, d.and))
            continue;

        if (d.notProcessingSteps && comparePS(d.notProcessingSteps, processingSteps, d.and))
            continue;

        sD = d;
        break;
    }
    return <span className={`status ${sD.color}`} title={sD.label + " - " + status}></span>;
};