import React from "react";
import SubmitButton from '../Form/SubmitButton';

const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1);
const utmList = utmObject => Object.keys(utmObject).length > 0 ? ['campaign', 'medium', 'source', 'term', 'content'].map(
    key => utmObject[key] ? (capitalize(key) + ': ' + utmObject[key]) : ''
).filter(s => s && s.length > 0).join(', ') : '---';

export default props => {
    return (
        <div className="flex">
            <p className="utm">UTM: <span>{utmList(props?.record?.utm ?? {})}</span></p>
            <SubmitButton {...props} />
        </div>
    );
};