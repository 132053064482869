import React, { isValidElement, useCallback, Fragment, cloneElement, createElement } from 'react';
import { linkToRecord, useExpanded, useListContext } from 'ra-core';
import { useNavigate } from 'react-router-dom';
import DataGridCell from './DataGridCell';
import { hideFieldWhenFilterDependent } from '../../utils/dependentOnFilter';

export default (props) => {
    const { children, record, resource, id, basePath, rowClick, handleToggleSelection, expand, expandClass } = props;
    const context = useListContext(props);
    const navigate = useNavigate();
    const [expanded, toggleExpanded] = useExpanded(resource, id);
    const handleToggleExpand = useCallback(
        event => {
            toggleExpanded();
            event.stopPropagation();
        },
        [toggleExpanded]
    );

    const handleClick = useCallback(
        async event => {
            if (!rowClick) return;
            event.persist();

            const effect =
                typeof rowClick === 'function'
                    ? await rowClick(id, basePath, record)
                    : rowClick;
            switch (effect) {
                case 'edit':
                    navigate(linkToRecord(id));
                    return;
                case 'show':
                    navigate(linkToRecord(id, 'show'));
                    return;
                case 'expand':
                    handleToggleExpand(event);
                    return;
                case 'toggleSelection':
                    handleToggleSelection(event);
                    return;
                default:
                    if (effect) navigate(effect);
                    return;
            }
        },
        [
            basePath,
            navigate,
            handleToggleExpand,
            handleToggleSelection,
            id,
            record,
            rowClick,
        ]
    );
    return (
        <Fragment>
            <tr onClick={handleClick}>
                {React.Children.map(children, (field, index) =>
                    isValidElement(field) && !hideFieldWhenFilterDependent(field, context.filterValues) ? (
                        <DataGridCell
                            key={`${id}-${field.props.source || index}`}
                            record={record}
                            handleToggleExpand={expand && handleToggleExpand}
                            {...{ field, basePath, resource }}
                        />
                    ) : null
                )}
            </tr>
            {expand && expanded && (
                <tr className={expandClass}>
                    {isValidElement(expand)
                        ? cloneElement(expand, {
                                // @ts-ignore
                                record,
                                basePath,
                                resource,
                                id: String(id),
                            })
                        : createElement(expand, {
                                record,
                                basePath,
                                resource,
                                id: String(id),
                            })
                    }
                </tr>
            )}
        </Fragment>
    );
};