// in src/Dashboard.js
import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
export default () => (
    <Card>
        <Title title="Welcome to the KREDIT.DE FPS" />
        <CardContent>[TODO]</CardContent>
    </Card>
);