import React, { useCallback } from "react";
import { Field } from 'react-final-form';

export default props => {
    const { isEditMode, source, label, formatData, record } = props;
    const formatCallback = useCallback((value) => {
        return formatData[value] ?? value;
    }, [formatData]);
    return (
        <Field name={source} format={formatData ? formatCallback : undefined}>
            {({ input, meta }) => {
                return (
                    <>
                        <p className="grey">{label}:</p>
                        <label className={"edit" + (isEditMode ? ' active' : '')}>
                            <input {...input} type="text" />
                            {!meta.dirty && !isEditMode && (<span>{record?.listing?.city}</span>)}
                            {(meta.error || meta.submitError) && meta.touched && (
                                <span className="error">{meta.error || meta.submitError}</span>
                            )}
                        </label>
                    </>
                );
            }}
        </Field>
    );
};