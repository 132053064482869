import React from "react";
import get from 'lodash/get';

const statusData = {
	approved: {
		color: 'green',
		label: 'Genehmight'
	},
	pending: {
		color: 'yellow',
		label: 'Wird übertragen'
	},
	rejected: {
		color: 'red',
		label: 'Abgelehnt'
	},
	error: {
		color: 'red',
		label: 'Übertragung fehlgeschlagen'
	},
	invalid: {
		color: 'red',
		label: 'Ungültig'
	},
	bankerror: {
		color: 'red',
		label: 'Bank Fehler'
	},
	systemerror: {
		color: 'red',
		label: 'System Fehler'
	},
	apierror: {
		color: 'red',
		label: 'API Fehler'
	},
};

export function StatusCCLeadField({record, source, }) {
	const value = get(record, source);
	const sD = statusData[value];
	return <span className={`status ${sD.color}`} title={sD.label}></span>;
}