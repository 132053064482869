import React from "react";
export default (props) => {
    const { isOpen, loading, title, confirmCancel, confirmOk, type, text, onConfirm, onClose} = props;
    return (
        <div className={`modal ${isOpen ? 'show' : ''}`} id={type}>
            <div className="modal-content">
                <i className="icon icon-clear" onClick={onClose}></i>
                <h2>{title}</h2>
                <hr className="line" />
                <p>{text}</p>
                {!loading && (
                    <div className="flex">
                        <button className="btn btn-blue" id="abort" onClick={onClose}>{confirmCancel}</button>
                <button className="btn" id="clear" onClick={onConfirm}>{confirmOk}</button>
                    </div>
                )}
                {loading && (
                    <div className={'loader show'}>
                        <img src="/img/loader.gif" alt="Ladebalken" title="Lädt" className={'loader show'} />
                    </div>
                )}
             </div>
        </div>
    );
};