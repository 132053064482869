import React from "react";

import List from './List';
import DataGrid from './List/DataGrid';
import TextField from './List/TextField';
import DateField from './List/DateField';
import StatusFormField from "./List/StatusFormField";

const defaultSort = { field: 'createdAt', order: 'DESC' };

export default (props) => {
  return (
    <List {...props} sort={defaultSort} perPage={10}>
      <DataGrid rowClick="show">
        <StatusFormField source="success" label="" notSearchable />
        <DateField source="createdAt" label="Datum" showTime notSearchable sortable />
        <TextField source="customer.firstName" label="Vorname" />
        <TextField source="customer.lastName" label="Nachname" />
        <TextField source="recipient.email" label="Empfänger" />
        <TextField source="website" label="Quelle" />
      </DataGrid>
    </List>
  );
};