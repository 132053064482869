import React from "react";
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';

const defaultSort = { field: 'createdAt', order: 'DESC' };

export default (props) => {
  return (
    <List {...props} sort={defaultSort} perPage={10}>
      <Datagrid rowClick="show">
        <TextField source="email" label="Kontakt" />
        <TextField source="name" label="Name" />
        <TextField source="scope" label="Scope" />
        <EditButton resource="admin/users" label="Edit User" />
        <DeleteButton resource="admin/users" label="Delete User" />
      </Datagrid>
    </List>
  );
};