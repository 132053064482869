import React from "react";
import get from 'lodash/get';

const currencyFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
const formatCurrency = value => isNaN(value) ? '' : currencyFormat.format(value);

export default ({ record, source, isCurrency, showExapandButton, handleToggleExpand, defaultValue = "" }) => {
    const value = get(record, source);
    return (<>
        {value == null ? defaultValue : isCurrency ? formatCurrency(value) : value}
        {showExapandButton && handleToggleExpand && (
            <i className="icon icon-eye" onClick={handleToggleExpand}></i>
        )}
    </>);
};