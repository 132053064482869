import React from 'react';
import get from 'lodash/get';

export default ({ record, source, statusData }) => {
  const status = get(record, source) ?? true;
  const isSent = !!(get(record, 'status.sentAt') ?? false);
  const isPrimarySent = !!(get(record, 'status.sentToPrimaryAt') ?? false);
  const hasCaseToken = !!(get(record, 'caseToken') ?? false);
  var sD = statusData.find((s) =>
    s.test({
      completed: status,
      sentAt: isSent,
      sentToPrimaryAt: isPrimarySent,
      caseToken: hasCaseToken,
    })
  );

  return (
    <span
      className={`status ${sD?.color}`}
      title={sD?.label + ' - ' + status}
    ></span>
  );
};
