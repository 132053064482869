import React from "react";

import List from './List';
import DataGrid from './List/DataGrid';
import EnumReplaceField from './List/EnumReplaceField';
import TextField from './List/TextField';
import DateField from './List/DateField';
import StatusCCField from "./List/StatusCCField";

const creditcardTypes = {
  "tfbank" : "TFBank",
  "hanseatic" : "Hanseatic"
};

const decisionTypes = {
  1 : "Genehmigt",
  2 : "Untersuchung",
  3 : "Abgelehnt",
  4 : "Ungültig",
  5 : "Fehler",
  green: "Genehmigt",
  yellow: "Untersuchung",
  red: "Abgelehnt",
};

const statusData = [
  {
    color: 'red',
    label: 'Übertragung fehlgeschlagen',
    success: false,
  },
  {
    color: 'green',
    label: 'Übertragung erfolgreich',
    success: true,
  },
  {
    color: 'yellow',
    label: 'Wird übertragen',
    processing: true,
  },
];

const defaultSort = { field: 'createdAt', order: 'DESC' };

export default (props) => {
  return (
    <List {...props} legendData={statusData} sort={defaultSort} perPage={10}>
      <DataGrid rowClick="show">
        <StatusCCField source="success" label="" notSearchable statusData={statusData} />
        <DateField source="createdAt" label="Datum" showTime notSearchable sortable />
        <EnumReplaceField source="lead.form_bank_type" label="Typ" replace={creditcardTypes} />
        <TextField source="lead.surname" label="Kontakt" />
        <TextField source="lead.city" label="Stadt" />
        <EnumReplaceField source="responseData.decision" label="Entscheidung" replace={decisionTypes} />
        <TextField source="website" label="Quelle" />
        <TextField source="lead.utm_campaign" label="UTM" notSearchable />
      </DataGrid>
    </List>
  );
};