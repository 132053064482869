import React, { useCallback, useState, useEffect } from "react";
import { Field } from 'react-final-form';
import Select from 'react-select';

const customStyles = {
    control: (provided) => ({
        ...provided,
        minWidth: 170,
        borderRadius: 0,
        border: "1px solid #E5E5E5",
    }),

    placeholder: (provided) => ({
        ...provided,
        opacity: 1,
        color: '#aaa'
    })
};

const convertReplaceToSelectOptions = (replace) => {
    if (!replace)
        return {};
    return Object.keys(replace).map(k => {
        return {
            value: k,
            label: replace[k]
        };
    });
};

export default props => {
    const { isEditMode, source, label, options, changeHandler } = props;
    const [selectOptions, setSelectOptions] = useState(convertReplaceToSelectOptions(options));
    useEffect(() => {
        if (!options)
            return;
        setSelectOptions(convertReplaceToSelectOptions(options));
    }, [options, setSelectOptions]);
    const formatCallback = useCallback((value) => {
        return options[value] ?? value;
    }, [options]);
    return (
        <>
            <p className="grey">{label}:</p>
            {!isEditMode && (
                <label className={"edit"}>
                    <Field name={source} component="input" format={options ? formatCallback : undefined} />
                </label>
            )}
            {isEditMode && (
                <label>
                    <Field name={source}>
                        {({ input /*, meta*/ }) => {
                            const inputCallback = useCallback(selection => {
                                input.onChange(selection.value);
                                if(changeHandler)
                                    changeHandler(selection.value);
                            }, [input]);
                            const [currentSelection, setCurrentSelection] = useState(selectOptions.find(s => s.value === input.value));
                            useEffect(() => {
                                setCurrentSelection(selectOptions.find(s => s.value === input.value));
                            }, [input]);
                            return <Select styles={customStyles} options={selectOptions} menuPosition="fixed" onChange={inputCallback} value={currentSelection} />;
                        }}
                    </Field>
                </label>
            )}
        </>
    );
};