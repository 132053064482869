import React, { useState, useEffect } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import config from '../config';

const theme = createTheme({});

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const token = searchParams.get('token');
    if (typeof token === 'string' && token.length > 0) {
      localStorage.setItem(config.AUTH_TOKEN_KEY, token);
      navigate('/');
    } else {
      navigate('/login');
    }
  }, [searchParams]);
  const login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    login({ email }).finally(() =>notify('Der Anmeldelink wurde an die angegebene E-Mail-Adresse gesendet'));
    setEmail('');
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="login">
        <div className="flex">
          <div className="left">
            <img className="logo" src="/img/logo.png" alt="Logo von KREDIT.DE" title="KREDIT.DE" />
          </div>
          <div className="right">
            <h2>Einloggen</h2>
            <p> Nachdem Sie Ihre E-Mail-Adresse übermittelt haben, wird Ihnen ein Anmeldelink per E-Mail zugesandt</p>
            <form method="post">
              <label>
                E-Mail
                <input name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </label>
              <button onClick={submit} className="btn">
                Abschicken
              </button>
            </form>
          </div>
        </div>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

export default LoginPage;

/*

<div class="login">
    <div class="flex">
        <div class="left">
            <img class="logo" src="/img/immolead.svg" alt="Logo von ImmoLead" title="ImmoLead">

            <h1>Herzlich willkommen im ImmoLead Backend.</h1>

        </div>
        <div class="right">
            <h2>Einloggen</h2>
            <form method="post">
                <label>
                    Benutzername
                    <input type="text">
                </label>

                <label>
                    Passwort
                    <input type="password">
                </label>
                <a href="/" class="btn">Einloggen</a>
                <!--<input type="submit" class="btn" value="Einloggen">-->
            </form>

        </div>
    </div>
</div>

*/
