import React from "react";
import { Field } from 'react-final-form';

export default props => {
    const { source, label, additionalLabel = false, stringify = false, minHeight = 100 } = props;
    return (
        <Field name={source} parse={stringify ? JSON.stringify : undefined}>
            {({ input, meta }) => (
                <>
                {additionalLabel && <p className="grey">{label}:</p>}
                    <textarea  {...input} className="textarea" style={{minHeight: minHeight + "px"}} placeholder={label} />
                    {(meta.error || meta.submitError) && meta.touched && (
                        <span className="error">{meta.error || meta.submitError}</span>
                    )}
                </>
            )}
        </Field>
    );
};