import React from "react";
import { Field } from 'react-final-form';

export default props => {
    const { isEditMode, source, label, options, record } = props;
    return (
        <>
            <p className="grey">{label}:</p>
            <div className={"right edit" + (isEditMode ? ' active' : '')}>
                {options && Object.keys(options).map(key => {
                    let value = key;
                    if (key === 'active' && record) {
                        value = record.listing.status === 'noInterest' || record.listing.status === 'inactive' ? record.listing.lastStatus : record.listing.status;
                    }
                    return (
                        <div key={key} className="radio">
                            <Field component="input" id={key} name={source} type="radio" value={value} />
                            <label htmlFor={key} className="radio-label">{options[key]}</label>
                        </div>
                    );
                })}
            </div>
        </>
    );
};