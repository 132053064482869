import React from "react";


export default ({ record, statusData }) => {

    const status = record?.success ?? false;
    const processing = (record?.requestStatus === 'scheduled' || record?.requestStatus === 'running') ?? false;
    var sD = statusData.find(s => s.success === status);
    if(processing) {
        sD = statusData.find(s => s.processing);
    } 
    
    return <span className={`status ${sD.color}`} title={sD.label + " - " + status}></span>;
};