import React, { useCallback, useState, useRef, useEffect } from 'react';
import throttle from 'lodash/throttle';
import Select from 'react-select';

const convertReplaceToSelectOptions = (replace) => {
    if (!replace)
        return {};
    return Object.keys(replace).map(k => {
        return {
            value: k,
            label: replace[k]
        };
    });
};

const customStyles = {
    control: (provided) => ({
        ...provided,
        minWidth: 170,
        borderRadius: 0,
        border: "1px solid #E5E5E5",
    }),

    placeholder: (provided) => ({
        ...provided,
        opacity: 1,
        color: '#aaa'
    })
};

export default (props) => {
    const { field, isSearchable, isSortable, currentSort, setSort, filterValues, setFilters, } = props;
    const { replace } = field.props;
    const [currentFilter, setCurrentFilter] = useState(filterValues[field.props.source]);
    const [selectOptions, setSelectOptions] = useState(convertReplaceToSelectOptions(replace));
    const sortCallback = useCallback(evt => {
        if (evt.target.tagName !== 'TH')
            return;
        setSort(field.props.source,
            currentSort.field === field.props.source ?
                (currentSort.order === 'ASC' ? 'DESC' : 'ASC') :
                'ASC'
        );
    }, [setSort, currentSort, field]);
    const filterCallback = useCallback(selection => {
        if (Array.isArray(selection)) {
            const obj = selection.reduce((prev, curr) => prev ? { ...prev, [curr.target]: curr.value } : { [curr.target]: curr.value }, {});
            setFilters({ ...filterValues, ...obj });
        } else {
            if (selection && typeof selection === 'object' && selection.target) {
                setCurrentFilter(selection.target.value);
                throttled.current(selection.target.value, filterValues);
            } else if (selection && typeof selection === 'object') {
                setCurrentFilter(selection);
                throttled.current(selection.value, filterValues);
            } else if (!selection) {
                setCurrentFilter('');
                throttled.current('', filterValues);
            }
        }
    }, [setCurrentFilter]);
    const throttled = useRef(throttle((newFilter, filters) => {
        setFilters({ ...filters, [field.props.source]: newFilter });
    }, 150));
    useEffect(() => {
        if (!replace)
            return;
        setSelectOptions(convertReplaceToSelectOptions(replace));
    }, [replace, setSelectOptions]);
    return (
        <th className={`sorting ${((isSearchable || isSortable) && 'search') ?? ''}`} onClick={!isSortable ? undefined : sortCallback}>
            {props.field.props.showOriginal && React.cloneElement(props.field, { ...props.field.props, onChange: filterCallback, value: currentFilter, filterValues })}
            {!props.field.props.showOriginal && isSearchable && !replace && <input type="text" placeholder={field.props.label ?? field.props.source} value={currentFilter} onChange={filterCallback} />}
            {!props.field.props.showOriginal && isSearchable && replace && (
                <Select styles={customStyles} options={selectOptions} menuPosition="fixed" isClearable onChange={filterCallback} value={currentFilter} placeholder={field.props.label ?? field.props.source} />
            )}
            {!props.field.props.showOriginal && !isSearchable && <span>{field.props.label ?? field.props.source}</span>}
        </th>
    );
};