import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';

export default function EditUser(props) {
  return (
	<Edit title='Edit User' { ...props } >
		<SimpleForm>
			<TextInput disabled source="id" label="Id" />
			<TextInput source="email" label="Email" />
			<TextInput source="name" label="Name" />
			<TextInput source="password" label="Password" />
			<SelectInput source="scope" label="Scope" choices={[
				{ id: 'admin', name: 'Admin' },
				{ id: 'dev', name: 'Dev' },
			]} />
		</SimpleForm>
	</Edit>
  );
}
