import React from "react";

export default props => {
    const { saving, isEditMode, buttonClassName = 'btn btn-blue' } = props;
    return (
        <>
            <div className={'loader' + (saving ? ' show' : '')}>
                <img src="/img/loader.gif" alt="Ladebalken" title="Lädt" className={'loader' + (saving ? ' show' : '')} />
            </div>
            <input type="submit" className={buttonClassName + (saving || !isEditMode ? ' hide' : '')} value="Speichern" />
        </>
    );
};