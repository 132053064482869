import React from "react";
import { Create, SimpleForm, TextInput, SelectInput } from "react-admin";

export default function CreateUser(props) {
  return (
    <Create title="Create User" {...props}>
      <SimpleForm>
        <TextInput source="email" label="Email" />
        <TextInput source="name" label="Name" />
        <TextInput source="password" label="Password" />
        <SelectInput
          source="scope"
          label="Scope"
          choices={[
            { id: "admin", name: "Admin" },
            { id: "dev", name: "Dev" },
          ]}
        />
      </SimpleForm>
    </Create>
  );
}
