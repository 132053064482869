import React from "react";
import SubmitButton from '../Form/SubmitButton';

const capitalize = value => value.substring(4).charAt(0).toUpperCase() + value.substring(4).slice(1);
const utmList = utmObject => Object.keys(utmObject).length > 0 ? ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content'].map(
    key => utmObject[key] ? (capitalize(key) + ': ' + utmObject[key]) : ''
).filter(s => s && s.length > 0).join(', ') : '---';

export default props => {
    return (
        <div className="flex">
            <p className="utm">Website: <span><b>{props?.record?.website}</b></span> UTM: <span>{utmList(props?.record?.lead ?? {})}</span></p>
            <SubmitButton {...props} />
        </div>
    );
};