import React from 'react';
import get from 'lodash/get';

export default ({ record, source, isEditMode, label }) => {
  const value = get(record, source);
  return (
    <>
      <p className="grey">{label}:</p>
      <label className="edit">
        {!isEditMode && value && <i className="icon icon-check verified"></i>}
        {!isEditMode && !value && <i className="icon icon-clear notVerified"></i>}
      </label>
    </>
  );
};
