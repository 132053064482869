import React, { useCallback } from "react";
import { useSidebarState } from 'react-admin';
//import LoadingIndicator from '../Components/LoadingIndicator';

const MyAppBar = props => {
    const [open, setOpen] = useSidebarState();

    const toggleSidebarCallback = useCallback(() => {
        setOpen(!open);
    }, [open, setOpen]);

    return (
        <header>
            <div className="burger" onClick={toggleSidebarCallback}>
                <div className="burger-bar">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <a href="/" className="logo" title="Dashboard">
                <img src="/img/logo.png" alt="Logo von KREDIT.DE" title="KREDIT.DE" />
            </a>
            <div className="menu">
                {/*<LoadingIndicator />*/}
                {props.logout}
            </div>
        </header>
    );
};



export default MyAppBar;