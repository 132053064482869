import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Show, SimpleForm, TextInput, SelectInput } from 'react-admin';

export default function User() {
    const history = useNavigate();

    const goBackCallback = useCallback(() => {
        history(-1);
    }, [history]);
    return (
        <div className={'details'}>
            <div className="flex space">
                <h2 className="symbol">User</h2>
                <button onClick={goBackCallback} className="btn btn-border">
                    <i className="icon icon-chevron-left"></i>Zurück
                </button>
            </div>
		<Show>
			<SimpleForm>
				<TextInput disabled source="id" label="Id" />
				<TextInput source="email" label="Email" />
				<TextInput source="name" label="Name" />
				<TextInput source="password" label="Password" />
				<SelectInput source="scope" label="Scope" choices={[
					{ id: 'admin', name: 'Admin' },
					{ id: 'dev', name: 'Dev' },
				]} />
			</SimpleForm>
		</Show>
        </div>
    );
}
