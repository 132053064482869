import React, { Children, cloneElement } from 'react';
import { useListController, ListContextProvider, useListContext } from 'ra-core';

import Legend from './Legend';
import Pageination from './Pageination';
import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    minwidth: 100,
    width: 100,
    borderRadius: 0,
    border: '1px solid #E5E5E5',
  }),

  placeholder: (provided) => ({
    ...provided,
    opacity: 1,
    color: '#aaa',
  }),
  container: (provided) => ({
    ...provided,
    display: 'inline-block'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

const options = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export const ListView = (props) => {
  const { children, bulkActionButtons, legendData, title, className, handleAdd, hidePagination = false, extraFilters = null} = props;
  const controllerProps = useListContext(props);
  const { perPage, setPerPage, total } = controllerProps;
  const onChange = (obj) => setPerPage(obj.value);
  return (
    <div className={className}>
      {legendData && <Legend {...controllerProps} legendData={legendData} title={title} handleAdd={handleAdd} />}
      {children &&
        cloneElement(Children.only(children), {
          ...controllerProps,
          hasBulkActions: bulkActionButtons !== false,
          extraFilters
        })}
      {
        !hidePagination && (
          <>
            <div className="dataTables_info" role="status">
              <Select isSearchable={false} styles={customStyles} menuPosition="fixed" options={options} onChange={onChange} value={{ value: perPage, label: `${perPage}` }} /> von{' '}
              {total} Ergebnissen
            </div>
            <Pageination {...controllerProps} />
          </>
        )
      }

    </div>
  );
};

const List = (props) => <ListContextProvider value={useListController(props)}><ListView {...props} /></ListContextProvider>;

export default List;
