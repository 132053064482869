import React, { useCallback } from "react";
import { NavLink } from 'react-router-dom';
export default (props) => {

    const classNameCallback = useCallback(({isActive}) => isActive ? 'active' : undefined, []);

    return (
        <NavLink
            className={classNameCallback}
            to={props.to}
            key={props.name}
            {...props}
        >
            {props.text}
        </NavLink>
    );
};