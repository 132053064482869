import { useListContext } from 'ra-core';
import React, {
    isValidElement,
    Children,
    cloneElement,
} from 'react';
import { hideFieldWhenFilterDependent } from '../../utils/dependentOnFilter';

import DatagridHeaderCell from './DatagridHeaderCell';
import DataGridRow from './DataGridRow';

export default (props) => {
    const { children, expand, expandClass,rowClick,tableClassName } = props;
    const {
        data,
        basePath,
        currentSort,
        resource,
        setSort,
        filterValues,
        setFilters,
         } = useListContext(props);
    return (
        <div className={tableClassName || "data-table"}>
            <table className={"dashboard"} id="table">
                <thead>
                {props?.extraFilters && isValidElement(props?.extraFilters) ? cloneElement(props?.extraFilters, {...props}) : null}
                    <tr role="row">
                        {Children.map(children, (field, index) =>
                            isValidElement(field) && !hideFieldWhenFilterDependent(field, filterValues) ? (
                                <DatagridHeaderCell
                                    currentSort={currentSort}
                                    field={field}
                                    isSorting={
                                        currentSort?.field ===
                                        (field?.props.sortBy || field?.props.source)
                                    }
                                    key={field?.props.source || index}
                                    resource={resource}
                                    setSort={setSort}
                                    isSearchable={!field?.props.notSearchable}
                                    isSortable={!field?.props.notSearchable || field?.props.sortable}
                                    filterValues={filterValues}
                                    setFilters={setFilters}
                                />
                            ) : null
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((record /*, rowIndex*/) =>
                        cloneElement(
                            <DataGridRow />,
                            {
                                basePath,
                                //hover,
                                id: record.id,
                                key: record.id,
                                //onToggleItem,
                                record,
                                resource,
                                rowClick,
                                expand,
                                expandClass
                            },
                            children
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
};
