import React from 'react';
import get from 'lodash/get';

export default ({ record, source, isEditMode }) => {
  const value = get(record, source);
  return (
    <label className="edit">
      {!isEditMode && value && value.verifiedAt && <i className="icon icon-check verified"></i>}
      {!isEditMode && (!value || !value.verifiedAt) && <i className="icon icon-clear notVerified"></i>}
    </label>
  );
};
