import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export default function CreatePartnerToken(props) {
  const handleDataTransform = (data) => {
    const newData = { ...data };
    const { creditLead } = newData.extra;

    creditLead.tracking.agent = creditLead.tracking.agent?.replace(/\s/g, "");
    creditLead.tracking.team = creditLead.tracking.team?.replace(/\s/g, "");

    if (!creditLead.tracking.agent && !creditLead.tracking.team) {
      creditLead.tracking = {};
    }

    return newData;
  };
  return (
    <Create
      title="Create Partner Token"
      transform={(data) => {
        return handleDataTransform(data);
      }}
      {...props}
    >
      <SimpleForm>
        <TextInput source="identifier" label="Identifier" required />
        <h3>Tracking for Creditlead</h3>
        <TextInput source="extra.creditLead.tracking.agent" label="Agent" />
        <TextInput source="extra.creditLead.tracking.team" label="Team" />
      </SimpleForm>
    </Create>
  );
}
