import * as React from 'react';
import { useEffect } from 'react';
import { Notification, useSidebarState } from 'react-admin';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppBar from './AppBar';
import SideBar from './SideBar';

const theme = createTheme({});

const ImmoLeadLayout = ({
    children,
    dashboard,
    logout,
    title,
}) => {
    const [open, setOpen] = useSidebarState();

    useEffect(() => {
        setOpen(true);
    }, [setOpen]);
    return (
        <ThemeProvider theme={theme}>
            <div>
                <AppBar title={title} open={open} logout={logout} />
                <div className="flex">
                    <SideBar hasDashboard={!!dashboard}>
                    </SideBar>
                    <div className={`content ${open ? '' : 'full'}`}>
                        {children}
                    </div>
                </div>
                <Notification />
            </div>
        </ThemeProvider>
    );
};

export default ImmoLeadLayout;