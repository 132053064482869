import React, { Fragment } from "react";
import BoolField from "./BoolField";
import TextInputField from "./TextInputField";
import JSONAreaInputField from './JSONAreaInputField';
const ForwardServiceList = ({record}) => {
    const services = Object.keys(record?.status?.forwarded).filter(name => typeof record.status.forwarded[name] === 'object' && 
        typeof record.status.forwarded[name].success === 'boolean');
    return (
        <>
        {services.map(name => {
            const success = record.status.forwarded[name].success;
            const lastJobIndex = record.status.forwarded[name]?.jobs.length - 1;
            return (
                <Fragment key={name}>
                    <BoolField key={name} source={`status.forwarded.${name}.success`} label={name} record={record} isEditMode={false}/>
                    {success === false && lastJobIndex >= 0 && <TextInputField source={`status.forwarded.${name}.jobs[${lastJobIndex}].statusCode`} label={`${name} Status`} />}
                    {success === false && lastJobIndex >= 0 && <JSONAreaInputField source={`status.forwarded.${name}.jobs[${lastJobIndex}].responseBody`} record={record} label={`${name} Response Body`} additionalLabel />}
                    <JSONAreaInputField source={`status.forwarded.${name}.requestBody`} record={record} label={`${name} Request Body`} additionalLabel />
                </Fragment>
            );
        })}
        </>
    );
};

export default ForwardServiceList;