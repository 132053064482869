import React, { useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useEditController } from 'react-admin';

import DetailForm from '../Form/DetailForm';
import TextInputField from '../Form/TextInputField';
import NumberInputField from '../Form/NumberInputField';
import DateInputField from '../Form/DateInputField';
import PostalCodeInputField from '../Form/PostalCodeInputField';
import SelectInputField from '../Form/SelectInputField';
import StatusRadioInputField from '../Form/StatusRadioInputField';
import MailStatus from '../Form/MailStatus';
import Reachability from '../Form/Reachability';
import ContactFooter from './ContactFooter';
import OverviewFooter from './OverviewFooter';
import EstimationView from '../Form/EstimationView';
import AdvisorContactStatus from "../Form/AdvisorContactStatus";

import useExportData from "../../utils/useExportData";

export const objectTypes = {
    flat: 'Wohnung',
    house: 'Einfamilienhaus',
    apartment_building: 'Mehrfamilienhaus',
    commercial_space: 'Gewerbefläche',
    commercial_building: 'Gewerbeobjekt',
    property: 'Grundstück',
    new_construction: 'Neubau',
    buy_real_estate: 'Kauf einer Immobilie',
    unknown: 'Unbekannt',
  };
  

const professionMainEarnerOptions = {
    'employee': 'Angestelle/r',
    'civil_servant': 'Beamte/r',
    'other': 'Sonstiges',
    'self_employed': 'Selbstständig',
    'retiree': 'Rentner/in',
    'privatier': 'Privatier',
    'student': 'Student/in'
};

const statusOptions = {
    'active': 'aktiv',
    'inactive': 'inaktiv',
    'noInterest': 'nicht mehr gewollt',
};

const usageOptions = {
    'inhabit': 'Selbst bewohnen',
    'part_rent': 'Teilweise vermieten',
    'rent': 'Vermieten'
};

const objectStatusOptions = {
    'object_search': 'Auf Objektsuche',
    'object_found': 'Objekt bereits gefunden',
    'in_negotiation': 'In Kaufverhandlung',
    'notary_date_planned': 'Notartermin geplant'
};

const financeTimeFrameOptions = {
    'lt_three': 'In weniger als 3 Monaten',
    'btw_three_six': 'In 3-6 Monaten',
    'gt_six': 'In über 6 Monaten'
};

const contactFormOptions = {
    'byphone': 'Telefon',
    'onsite': 'Filiale',
    'videochat': 'Videochat'
};




const View = props => {
    console.log(props);
    return (
        <div className="flex row">
            <div className="flex-item">
                <DetailForm
                    formLabel="Übersicht"
                    boxClassName="overview"
                    {...props}
                >
                    <SelectInputField source="listing.objectType" label="Objekt" options={objectTypes} />
                    <NumberInputField source="listing.loanAmount" label="Darlehenssummme" currency />
                    <NumberInputField source="listing.listedValue" label="Kaufpreis" currency />
                    <NumberInputField source="listing.equity" label="Eigenkapital" currency />
                    <PostalCodeInputField source="listing.postalCode" label="Postleitzahl" />
                    <SelectInputField source="listing.usage" label="Nutzung" options={usageOptions} />
                    <SelectInputField source="listing.objectStatus" label="Status Immobiliensuche" options={objectStatusOptions} />
                    <SelectInputField source="listing.financeTimeFrame" label="Wann Finanzierung" options={financeTimeFrameOptions} />
                    <StatusRadioInputField source="listing.status" label="Status der Finanzierung" options={statusOptions} />
                    <OverviewFooter />
                </DetailForm>
                <DetailForm
                    formLabel="Kontaktdaten"
                    boxClassName="contact"
                    {...props}
                >
                    <TextInputField source="customer.firstName" label="Vorname" />
                    <TextInputField source="customer.lastName" label="Nachname" />
                    {props?.record?.customer.address && <TextInputField source="customer.address.street" label="Straße" /> }
                    {props?.record?.customer.address && <TextInputField source="customer.address.number" label="Hausnummer" /> }
                    {props?.record?.customer.address && <TextInputField source="customer.address.postalCode" label="Postleitzahl" /> }
                    {props?.record?.customer.address && <TextInputField source="customer.address.city" label="Ort" /> }
                    <TextInputField source="customer.email" label="E-Mail-Adresse" />
                    <MailStatus source="customer.verified" label="E-Mail bestätigt" />
                    <TextInputField source="customer.phone" label="Telefonnummer" />
                    <TextInputField source="customer.additionalPhone" label="Weitere Telefonnummer" />
                    {props?.record?.customer?.contactForm && <SelectInputField source="customer.contactForm" label="Wünscht Angebotserstellung über" options={contactFormOptions} />}
                    <AdvisorContactStatus source="listing.advisorMayContact" label="Wünscht telefonische Beratung" />
                    <Reachability source="customer.reachability" label="Erreichbarkeit" />
                    {props?.record?.customer?.birthYear && <TextInputField source="customer.birthYear" label="Geburtsjahr" />}
                    {!props?.record?.customer?.birthYear && <DateInputField source="customer.birthDate" label="Geburtsdatum" />}
                    <NumberInputField source="customer.income" label="montl. Nettoeinkommen" currency />
                    <NumberInputField source="listing.monthlyHouseholdIncome" label="Haushaltsnetto mtl." currency />
                    <SelectInputField source="listing.professionMainEarner" label="Beruf Hauptverdiener" options={professionMainEarnerOptions} />

                    {props?.record?.customer2 && <p className="bold">Angaben zweite Person:</p>}
                    {props?.record?.customer2 && <TextInputField source="customer2.firstName" label="Vorname" />}
                    {props?.record?.customer2 && <TextInputField source="customer2.lastName" label="Nachname" />}
                    {props?.record?.customer2 && <TextInputField source="customer2.email" label="E-Mail-Adresse" />}
                    {props?.record?.customer2 && <TextInputField source="customer2.phone" label="Telefonnummer" />}
                    {props?.record?.customer2 && <Reachability source="customer2.reachability" label="Erreichbarkeit" />}
                    {props?.record?.customer2 && <DateInputField source="customer2.birthDate" label="Geburtsdatum" />}
                    {props?.record?.customer2 && <NumberInputField source="customer2.income" label="montl. Nettoeinkommen" currency />}

                    <ContactFooter />
                </DetailForm>
            </div>
            <div className="flex-item">
                <DetailForm
                    formLabel="Ermittelter Durchschnittswert"
                    boxClassName=""
                    noEditMode
                    {...props}
                >
                   <EstimationView />
                </DetailForm>
            </div>    
        </div>
    );
};


export default props => {
    const history = useNavigate();
    const editProps = useEditController({ ...props, undoable: false });
    const { exportCallback, viewCallback } = useExportData(editProps.record);

    const goBackCallback = useCallback(() => {
        history(-1);
    }, [history]);

    return (
        <div className={'finance details'}>
            <div className="flex space">
                <h2 className="symbol">Immobilienfinanzierung – Lead</h2>
                <button onClick={goBackCallback} className="btn btn-border"><i className="icon icon-chevron-left"></i>Zurück</button>
            </div>
            <View {...editProps} />
            <button className="export" onClick={exportCallback}>Export</button>
            <button className="export" onClick={viewCallback}>View</button>
        </div>
        
    );
};

