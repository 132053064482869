import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEditController } from 'react-admin';

import DeleteButton from '../DeleteButton';
import DetailForm from '../Form/DetailForm';
import SelectInputField from '../Form/SelectInputField';
import TextInputField from '../Form/TextInputField';

import useExportData from "../../utils/useExportData";

const genderTypes = {
    male: 'Herr',
    female: 'Frau',
    diverse: 'Divers',
};

const decisionTypes = {
    approved: 'Genehmight',
	pending: 'Wird geprüft',
	rejected: 'Abgelehnt',
	error: 'Fehler',
	invalid: 'Ungültig',
	bankerror: 'Bank Error',
	systemerror: 'System Error',
	apierror: 'API Error',
};

const statusTypes = {
    scheduled: 'Ausstehend',
	running: 'In arbeit',
	finished: 'Abgeschlossen',
};

const T = (props) => {
    return (
        <div className="flex row">
            <div className="flex-item">
                <DetailForm formLabel="Kontaktdaten" boxClassName="contact" {...props}>
                    <SelectInputField source="lead.gender" label="Geschlecht" options={genderTypes} />
                    <TextInputField source="lead.firstname" label="Vorname" isEditMode={false} />
                    <TextInputField source="lead.lastname" label="Nachname" />
					<TextInputField source="lead.email" label="E-Mail" />
					<TextInputField source="lead.phonenumber" label="Telefonnummer" />
                </DetailForm>
            </div>
            <div className="flex-item">
                <DetailForm formLabel="Antwort" boxClassName="contact" {...props}>
                    <SelectInputField source="status.decision" label="Entscheidung" options={decisionTypes} />
                    <SelectInputField source="status.requestStatus" label="Status" options={statusTypes} />
                </DetailForm>
            </div>
        </div>
    );
};

export function LeadsCCLead(props) {
    const history = useNavigate();
    const editProps = useEditController({ ...props, undoable: false });
    const { exportCallback, viewCallback } = useExportData(editProps.record);

    const goBackCallback = useCallback(() => {
        history(-1);
    }, [history]);
    return (
        <div className={'details'}>
            <div className="flex space">
                <h2 className="symbol">Lead</h2>
                <button onClick={goBackCallback} className="btn btn-border">
                    <i className="icon icon-chevron-left"></i>Zurück
                </button>
            </div>
            <T {...editProps} />
            <button className="export" onClick={exportCallback}>Export</button>
            <button className="export" onClick={viewCallback}>View</button>
            {editProps.record && <DeleteButton {...editProps} />}
        </div>
    );
}
