import { useCallback } from 'react';

/**
 * @param {Object} data - Data to export
 *
 * @returns {{
 *   exportCallback: () => void,
 *   viewCallback: () => void,
 * }}
 */
export default function useExportData(data) {
    const exportCallback = useCallback(() => {
        if (!data) return;
        const json = JSON.stringify(data, null, 2);
        // eslint-disable-next-line no-undef
        const dlElement = document.createElement('a');
        dlElement.href = `data:application/json;charset=utf-8,${encodeURI(json)}`;
        dlElement.target = '_blank';
        dlElement.download = `lead-${data.id}.json`;
        dlElement.click();

        // eslint-disable-next-line no-undef
        alert('Hinweise: Die Daten bitte nicht unnötig lange auf dem PC lassen!');
    }, [data]);

    const viewCallback = useCallback(() => {
        if (!data) return;
        const json = JSON.stringify(data, null, 2);
        const url = `data:application/json;base64,${btoa(json)}`;
        const iframe = `
            <body style="margin:0">
                <iframe
                    frameborder="0"
                    style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;"
                    allowfullscreen
                    src="${url}"
                />
            </body>
        `;

        // eslint-disable-next-line no-undef
        const win = window.open();
        win.document.open();
        win.document.write(iframe);
        win.document.close();
    }, [data]);

    return { exportCallback, viewCallback };
}
