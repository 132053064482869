import React from "react";
import get from 'lodash/get';

//const dateFormat = {};
//const dateWithTimeFormat = {};

export default ({ record, source, showTime, dateFormat }) => {
    const value = get(record, source);
    const date = new Date(value);
    if(dateFormat) {
        return<>{dateFormat(date)}</>;
    }
    return (<>{showTime ? date.toLocaleString() : date.toLocaleDateString()}</>);
};