import React from "react";

const currencyFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
const formatCurrency = value => isNaN(value) ? '' : currencyFormat.format(value);

const formatDate = value => new Date(value).toLocaleDateString();

const InfoComponent = ({estimation, area}) => {
    return (
        <p className="utm">
            {area && (<span>m&sup2;-Preis: {formatCurrency(estimation.avgPerSquareMeter)}. </span>)}
            {estimation.postalCode && (<span>PLZ-Bereich: {estimation.postalCode.replace('%', '')}. </span> )}
            {(area || estimation.postalCode) && [<br key={""+area}/>]}
            <span>Berechnet aus {estimation.dataSets} Datensätzen. Stand: {formatDate(estimation.queriedAt)}</span>
        </p>
    );
};

export default props => {
    const { record } = props;
    const estimation = record?.listing?.internalEstimation;
    const area = record?.listing?.area;
    if(estimation?.avgPerSquareMeter && area) {
        return (
            <>
                <p className="average">
                    {formatCurrency(estimation.avgPerSquareMeter * area)}
                </p>
                <InfoComponent estimation={estimation} area/>
            </>
        );
    } else if(estimation?.avgPerSquareMeter){
        return (
            <>
                <p className="average">
                    {formatCurrency(estimation.avgPerSquareMeter)} (m&sup2;-Preis)
                </p>
                <InfoComponent estimation={estimation}/>
            </>
        );
    } 
    return (
        <p>Keine Daten verfügbar</p>
    );
};