import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';

export default function CreateWebsiteToken(props) {
  return (
    <Create title='Create Partner Token' {...props}>
      <SimpleForm>
        <TextInput
          source='domain'
          label='Domain'
          format={(v) => v?.trim()}
          required
        />
        <BooleanInput label='Send DOI Email' source='sendDOIEmail' />
      </SimpleForm>
    </Create>
  );
}
