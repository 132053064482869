import React from 'react';

import List from './List';
import DataGrid from './List/DataGrid';
import TextField from './List/TextField';
import DateField from './List/DateField';
import { StatusCCLeadField } from './List/StatusCCLeadField';

const statusData = [
    {
        color: 'red',
        label: 'Übertragung fehlgeschlagen',
        success: false,
    },
    {
        color: 'green',
        label: 'Übertragung erfolgreich',
        success: true,
    },
    {
        color: 'yellow',
        label: 'Wird übertragen',
        processing: true,
    },
];

const defaultSort = { field: 'createdAt', order: 'DESC' };

export default (props) => {
    return (
        <List {...props} legendData={statusData} sort={defaultSort} perPage={10}>
            <DataGrid rowClick="show">
                <StatusCCLeadField source="status.decision" label="Entscheidung" notSearchable />
                {/* <StatusCCField source="success" label="" notSearchable statusData={statusData} /> */}
                <DateField source="createdAt" label="Datum" showTime notSearchable sortable />
                <TextField source="lead.lastname" label="Kontakt" />
                <TextField source="lead.city" label="Stadt" />
                <TextField source="website" label="Quelle" />
                <TextField source="lead.utm_campaign" label="UTM" notSearchable />
            </DataGrid>
        </List>
    );
};
