import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
    getList: (resource, params) => {
        let modResource = '' + resource;
        let modFilter = { ...params.filter };
        const filterOptions = modResource.split('_');
        if (filterOptions && filterOptions.length === 3 && filterOptions[1] !== 'credits' && filterOptions[1] !== 'creditcards' && filterOptions[1] !== 'baufi') {
            let [path, filterKey, filterValue] = filterOptions;
            if (filterValue !== 'all' && filterKey === 'type')
                modFilter[filterKey] = filterValue;
            if (filterValue !== 'all' && filterKey === 'status') {
                if (!modFilter.listing)
                    modFilter.listing = {};
                modFilter.listing[filterKey] = filterValue;
            }
            modResource = path;
        }

        if(filterOptions && (filterOptions[1] === "credits" || filterOptions[1] === 'creditcards' || filterOptions[1] === 'baufi')) {
            modResource = modResource.split('_').join('/');
        }
        
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            filter: encodeURI(JSON.stringify({ ...fetchUtils.flattenObject(modFilter) })),
            sort: field,
            order: order,
            offset: (page - 1) * perPage,
            limit: perPage,
        };
        const url = `${apiUrl}/${modResource}?${stringify(query)}`;

        return httpClient(url).then(({ json }) => {
            const dataKey = Object.keys(json.data).filter(e => e !== 'total')[0];
            return {
                data: json.data[dataKey],
                total: parseInt(json.data.total, 10),
            };
        });
    },

    getOne: (resource, params) => {
        let modResource = '' + resource;
        const filterOptions = modResource.split('_');
        if (filterOptions.length === 3)
            modResource = filterOptions[0];
        if(filterOptions && filterOptions[1] && (filterOptions[1] === "credits" || filterOptions[1] === 'creditcards' || filterOptions[1] === 'baufi')) {
            modResource = `${resource}`.split('_').join('/');
        }
        return httpClient(`${apiUrl}/${modResource}/${params.id}
        `).then(({ json }) => ({
            data: json.data
        }));
    },

    getMany: (resource, params) => {
        const query = {
            id: params.ids,
        };
        let modResource = '' + resource;
        const filterOptions = modResource.split('_');
        if (filterOptions.length === 3)
            modResource = filterOptions[0];
        const url = `${apiUrl}/${modResource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json.data }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: json,
                total: parseInt(
                    headers
                        .get('x-total-count')
                        .split('/')
                        .pop(),
                    10
                ),
            };
        });
    },

    update: (resource, params) => {
        let modResource = '' + resource;
        const filterOptions = modResource.split('_');
        if (filterOptions.length === 3)
            modResource = filterOptions[0];
        return httpClient(`${apiUrl}/${modResource}/${params.id}`, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json.data }));
    },

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) => {
        let modResource = '' + resource;
        const filterOptions = modResource.split('_');
        if (filterOptions.length === 3)
            modResource = filterOptions[0];
        return Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${modResource}/${id}`, {
                    method: 'PATCH',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.data.id) }));
    },
        
    create: (resource, params) => {
        let modResource = '' + resource;
        const filterOptions = modResource.split('_');
        if (filterOptions.length === 3)
            modResource = filterOptions[0];
        return httpClient(`${apiUrl}/${modResource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.data.id },
        }));
    },
        
    delete: (resource, params) => {
        let modResource = '' + resource;
        const filterOptions = modResource.split('_');
        if (filterOptions.length === 3)
            modResource = filterOptions[0];
        return httpClient(`${apiUrl}/${modResource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    },
        
    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) => {
        let modResource = '' + resource;
        const filterOptions = modResource.split('_');
        if (filterOptions.length === 3)
            modResource = filterOptions[0];
        return Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${modResource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) }));
    },
        
});
